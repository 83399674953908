export const generateMonthOptions = () => {
  const monthOptions = [
    { value: '01', label: 'JAN' },
    { value: '02', label: 'FEB' },
    { value: '03', label: 'MAR' },
    { value: '04', label: 'APR' },
    { value: '05', label: 'MAY' },
    { value: '06', label: 'JUN' },
    { value: '07', label: 'JUL' },
    { value: '08', label: 'AUG' },
    { value: '09', label: 'SEP' },
    { value: '10', label: 'OCT' },
    { value: '11', label: 'NOV' },
    { value: '12', label: 'DEC' },
  ];

  return monthOptions.map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ));
};

export const generateYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 100;
  const yearOptions = [];
  for (let i = startYear; i <= currentYear; i++) {
    yearOptions.push(
      <option key={i} value={i}>
        {i}
      </option>,
    );
  }
  return yearOptions;
};

export const generateDayOptions = () => {
  const dayOptions = [];
  for (let i = 1; i <= 31; i++) {
    const day = i < 10 ? `0${i}` : `${i}`;
    dayOptions.push(
      <option key={day} value={day}>
        {day}
      </option>,
    );
  }
  return dayOptions;
};
