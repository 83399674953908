import React from 'react';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import router from 'next/router';
import { useAccount } from 'frontastic';
const PasswordMatchRequirements = (password: string, userName?: string) => {
  const rightIcon = (
    <svg
      className="ml-2 inline-block h-4 w-4 text-green-500"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 12"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 5.917 5.724 10.5 15 1.5"
      />
    </svg>
  );
  const wrongIcon = (
    <svg
      className="ml-2 h-4 w-4 text-red-500 dark:text-white"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
      />
    </svg>
  );
  const { account } = useAccount();

  const validatePassword = (password) => {
    const mustDifferentFromUsername =
      account && account?.accountId ? password !== account?.userName : password !== userName;
    const doesNotContainSpaces = !password?.includes(' ');
    const hasMinimumLength = password?.length >= 6;
    const hasMaximumLength = password?.length <= 15;
    const hasUppercase = /[A-Z]/.test(password);
    const hasDigit = /[0-9]/.test(password);
    const allowedSpecialCharacters = `!^[]’'"@#$%&*()_+=|}{;:<,.>/?”`;
    let hasAllowedSpecialCharacters = false;

    for (const char of allowedSpecialCharacters) {
      if (password.includes(char)) {
        hasAllowedSpecialCharacters = true;
        break;
      }
    }
    return {
      mustDifferentFromUsername,
      doesNotContainSpaces,
      hasMinimumLength,
      hasMaximumLength,
      hasUppercase,
      hasDigit,
      hasAllowedSpecialCharacters,
    };
  };

  const isValid = () => {
    return (
      passwordValidation.mustDifferentFromUsername &&
      passwordValidation.doesNotContainSpaces &&
      passwordValidation.hasMinimumLength &&
      passwordValidation.hasMaximumLength &&
      passwordValidation.hasUppercase &&
      passwordValidation.hasDigit &&
      passwordValidation.hasAllowedSpecialCharacters
    );
  };
  const passwordValidation = validatePassword(password);
  return (
    <>
      {isValid() ? null : (
        <div className="mt-2 rounded-sm bg-gray-200 ">
          <ul>
            <div className="flex items-center">
              {passwordValidation.mustDifferentFromUsername ? rightIcon : wrongIcon}
              <li
                className={passwordValidation.mustDifferentFromUsername ? 'ml-2 text-green-500' : 'ml-2 text-red-500'}
              >
                {mapLocaleToMeaningfulFormat(router.locale).mustDifferentFromUsername}
              </li>
            </div>
            <div className="flex items-center">
              {passwordValidation.doesNotContainSpaces ? rightIcon : wrongIcon}
              <li className={passwordValidation.doesNotContainSpaces ? 'ml-2 text-green-500' : 'ml-2 text-red-500'}>
                {mapLocaleToMeaningfulFormat(router.locale).mustNotContainSpaces}
              </li>
            </div>
            <div className="flex items-center">
              {passwordValidation.hasMinimumLength ? rightIcon : wrongIcon}
              <li className={passwordValidation.hasMinimumLength ? ' ml-2 text-green-500' : 'ml-2 text-red-500'}>
                {mapLocaleToMeaningfulFormat(router.locale).minimumPasswordLength}
              </li>
            </div>
            <div className="flex items-center">
              {passwordValidation.hasMaximumLength ? rightIcon : wrongIcon}
              <li className={passwordValidation.hasMaximumLength ? 'ml-2 text-green-500' : 'ml-2 text-red-500'}>
                {mapLocaleToMeaningfulFormat(router.locale).maximumPasswordLength}
              </li>
            </div>
            <div className="flex items-center">
              {passwordValidation.hasUppercase ? rightIcon : wrongIcon}
              <li className={passwordValidation.hasUppercase ? 'ml-2 text-green-500' : 'ml-2 text-red-500'}>
                {mapLocaleToMeaningfulFormat(router.locale).minimumUpperCaseCharacter}
              </li>
            </div>
            <div className="flex items-center">
              {passwordValidation.hasDigit ? rightIcon : wrongIcon}
              <li className={passwordValidation.hasDigit ? 'ml-2 text-green-500' : 'ml-2 text-red-500'}>
                {mapLocaleToMeaningfulFormat(router.locale).minimumDigit}
              </li>
            </div>
            <div className="flex items-center">
              {passwordValidation.hasAllowedSpecialCharacters ? rightIcon : wrongIcon}
              <li
                className={passwordValidation.hasAllowedSpecialCharacters ? 'ml-2 text-green-500' : 'ml-2 text-red-500'}
              >
                {mapLocaleToMeaningfulFormat(router.locale).allowSpecialCharacters} {'!^[]’@#$%&*()_+=|}{;:<,.>/?”'}
              </li>
            </div>
          </ul>
        </div>
      )}
      <div></div>
    </>
  );
};
export default PasswordMatchRequirements;
