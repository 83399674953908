import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

function SecureCheckout({ closeModal, informationRequired }) {
  return (
    <>
      <Transition appear show={informationRequired} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={closeModal}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-black fixed inset-0 bg-opacity-25" />
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <section className="absolute inset-0" onClick={closeModal}>
                  <section
                    className="absolute top-1/2 left-1/2 h-[618px] w-[358px] -translate-x-1/2 -translate-y-1/2 overflow-y-auto rounded bg-white px-[30px] pt-8 pb-4 dark:bg-primary-200 xl:h-[530px] xl:w-[1250px]"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <section className="absolute top-3 right-3"></section>
                    <section className="w-[100%] text-left">
                      <section className="flex justify-between">
                        <h3 className="text-xl font-bold">Why Is My Personal Information Required?</h3>
                        <button
                          type="button"
                          className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-900 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                          onClick={closeModal}
                        >
                          <svg
                            aria-hidden="true"
                            className="h-5 w-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span className="sr-only">Close modal</span>
                        </button>
                      </section>

                      <div className="space-y-4 overflow-y-auto py-3">
                        <p>
                          Only authorized Exchange customers can order from the all services Exchange Online Store and
                          online Mall. Since we are unable to validate eligibility online by viewing each
                          customer&apos;s Identification and Privilege Card, we must validate eligibility through the
                          Defense Enrollment Eligibility Reporting System (DEERS) and therefore the only information
                          available for eligibility validation purposes is social security number and date of birth. For
                          this reason, the first time customers log-in to our site we are required to obtain the last 4
                          digits of social security number, along with date of birth and last name. The first time
                          customers enter our site, however, they will be required to change their social security
                          number to a new Username. Please be assured that we do not retain your social security number
                          outside of the DEERS system and we take every measure to safeguard your personal information
                          (for further information refer to our Online Safety Guarantee).
                        </p>
                        <p>
                          Except for verifying patronage eligibility or authorized law enforcement investigations, no
                          other attempts are made to identify individual users or their usage habits. Raw data logs are
                          not used for any other purposes and are scheduled for destruction in accordance with AAFES
                          internal guidelines.
                        </p>
                        <p>
                          If you have any questions or comments about the information presented here, please visit our
                          Contact AAFES page.
                        </p>
                      </div>
                    </section>
                  </section>
                </section>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default SecureCheckout;
