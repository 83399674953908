import {
  GA_CONTINUE_TO_MARKETPLACE,
  GA_SELECT_ITEM,
  GA_SERVER_URL,
  GA_VIEW_CART,
  GA_VIEW_ITEM,
  GA_VIEW_ITEM_LIST,
  GA_VIEW_CLP,
} from 'helpers/constants/aafes';
import { useCallback } from 'react';
import useGATrackUserAction from './useGATrackUserAction';

interface UserProperties {
  cid: string;
  segmentType: string;
  milstat: string;
  region: string;
  gender: string;
  myStore: string;
  timeZone: string;
  age: number;
}

export const getUserProperties = (account: any, calculateAge: (birthday: string) => number): UserProperties => {
  return {
    cid: account?.cid || '',
    segmentType: account?.branchOfServiceDescription || '',
    milstat: account?.militaryStatus || '',
    region: 'US',
    timeZone: 'CST',
    gender: account?.gender || '',
    myStore: account?.defaultStore?.name || '',
    age: calculateAge(account?.birthday || ''),
  };
};

export const setCategoryValues = (product: any) => {
  const categories = product?.categories ?? [];
  const parentCategories = categories?.[0]?.parent?.length > 0 ? categories?.[0]?.parent : [];
  const modifiedCategories =
    categories?.length > 0
      ? [...parentCategories, categories?.[0]]?.filter((category) => category?.name !== 'Navigation Tree')
      : [];
  return modifiedCategories;
};

const useGATrackViewActions = () => {
  const { calculateAge } = useGATrackUserAction();
  const getCurrentUrl = (): string => {
    return window.location.href;
  };

  const getSeverUrl = (): string => {
    return window.location.host;
  };

  const trackViewItem = useCallback((trackViewItemObj: any) => {
    const { product, account } = trackViewItemObj;
    const gtag = window?.gtag;
    try {
      const categories = setCategoryValues(product);
      const viewItem = {
        currency: product?.price?.currencyCode,
        value: product?.price?.centAmount / 10 ** product?.price?.fractionDigits,
        user_properties: getUserProperties(account, calculateAge),
        items: [
          {
            item_id: product?.variants?.[0]?.sku,
            item_name: product?.name,
            product_id: product?.productId,
            item_brand: product?.variants?.[0]?.attributes?.brand || '',
            item_category: categories?.[0]?.name || '',
            item_category2: categories?.[1]?.name || '',
            item_category3: categories?.[2]?.name || '',
            item_category4: categories?.[3]?.name || '',
            size: '',
            color: '',
          },
        ],
        webPageDetails: {
          name: 'product',
          server: getSeverUrl(),
          page_url: getCurrentUrl(),
          pageId: 'product Page',
          siteSection: categories?.[0]?.name || '',
          siteSectionLevel2: categories?.[1]?.name || '',
          siteSectionLevel3: categories?.[2]?.name || '',
          siteSectionLevel4: categories?.[3]?.name || '',
        },
      };
      gtag('event', GA_VIEW_ITEM, viewItem);
    } catch (error) {
      console.error('ga4 view item event fail', error);
    }
  }, []);

  const trackViewCart = useCallback((trackViewCartObj: any) => {
    const { cart, account } = trackViewCartObj;
    const gtag = window?.gtag;
    try {
      const viewCart = {
        currency: cart?.total?.currencyCode,
        value: cart?.total?.centAmount / 10 ** cart?.total?.fractionDigits,
        coupon: cart?.discountCodes?.[0]?.code || '',
        user_properties: getUserProperties(account, calculateAge),
        items: cart?.lineItems.map((lineItem) => ({
          item_id: lineItem?.variant?.sku,
          item_name: lineItem?.name,
          discount: 0,
          product_id: lineItem?.variant?.productKey,
          item_brand: lineItem?.variant?.attributes?.brand || '',
          size: lineItem?.variant?.picker?.Size || '',
          item_category: lineItem?.productCategories?.[0] || '',
          item_category2: lineItem?.productCategories?.[1] || '',
          item_category3: lineItem?.productCategories?.[2] || '',
          item_category4: lineItem?.productCategories?.[3] || '',
          color: lineItem?.variant?.picker?.Color || '',
          price: lineItem?.price?.centAmount / 10 ** lineItem?.price?.fractionDigits,
          quantity: lineItem?.count,
          revenue: lineItem?.totalPrice?.centAmount / 10 ** lineItem?.totalPrice?.fractionDigits,
        })),
        webPageDetails: {
          name: 'Cart',
          server: getSeverUrl(),
          page_url: getCurrentUrl(),
          pageId: 'Cart Page',
          siteSection: 'Shopping Cart',
          siteSectionLevel2: '',
          siteSectionLevel3: '',
          siteSectionLevel4: '',
        },
      };
      gtag('event', GA_VIEW_CART, viewCart);
    } catch (error) {
      console.error('ga4 view cart event fail', error);
    }
  }, []);

  const trackViewItemList = useCallback((trackViewItemListObj: any) => {
    const { res, account } = trackViewItemListObj;
    const gtag = window?.gtag;
    const itemCategories = res?.records?.[0]?.parentCategories?.[0]?.split(' > ');
    try {
      const viewItemList = {
        user_properties: getUserProperties(account, calculateAge),
        page_url: getCurrentUrl(),
        page_title: document?.title,
        siteSection: itemCategories?.[0] || '',
        siteSection2: itemCategories?.[1] || '',
        siteSection3: itemCategories?.[2] || '',
        siteSection4: itemCategories?.[3] || '',
        items: res?.records?.map((records) => {
          const categories = records?.parentCategories?.[0]?.split(' > ');
          return {
            item_id: records?.variants?.[0]?.skuId,
            item_name: records?.displayName,
            product_id: records?.variants?.[0]?.skuId,
            item_category: categories?.[0] || '',
            item_category2: categories?.[1] || '',
            item_category3: categories?.[2] || '',
            item_category4: categories?.[3] || '',
            size: '',
            color: '',
            price: records?.variants?.[0]?.activePrice,
          };
        }),
      };
      if (!viewItemList?.items) {
        viewItemList.siteSection = document?.title?.split('|')[0];
        gtag('event', GA_VIEW_CLP, viewItemList);
      } else {
        gtag('event', GA_VIEW_ITEM_LIST, viewItemList);
      }
    } catch (error) {
      console.error('ga4 view_item_list event fail', error);
    }
  }, []);

  const trackSelectItem = useCallback((trackSelectItemObj: any) => {
    const { product, account } = trackSelectItemObj;
    const gtag = window?.gtag;
    try {
      const categories = product?.parentCategories?.[0]?.split(' > ');
      const selectItem = {
        user_properties: getUserProperties(account, calculateAge),
        page_url: getCurrentUrl(),
        items: [
          {
            item_id: product?.variants?.[0]?.skuId,
            item_name: product?.displayName,
            product_id: product?.variants?.[0]?.skuId,
            item_category: categories?.[0] || '',
            item_category2: categories?.[1] || '',
            item_category3: categories?.[2] || '',
            item_category4: categories?.[3] || '',
            size: '',
            color: '',
            price: product?.variants?.[0]?.activePrice,
          },
        ],
      };
      gtag('event', GA_SELECT_ITEM, selectItem);
    } catch (error) {
      console.error('ga4 select_Item event fail', error);
    }
  }, []);

  const trackMarketplaceCTA = useCallback((trackMarketplaceCTAObj: any) => {
    const { product, account } = trackMarketplaceCTAObj;
    const gtag = window?.gtag;
    try {
      const marketPlaceNav = {
        user_properties: getUserProperties(account, calculateAge),
        page_url: getCurrentUrl(),
        items: [
          {
            item_id: product?.variants?.[0]?.sku,
            item_name: product?.name,
          },
        ],
      };
      gtag('event', GA_CONTINUE_TO_MARKETPLACE, marketPlaceNav);
    } catch (error) {
      console.error('ga4 MarketplaceCTA event fail', error);
    }
  }, []);

  return {
    trackViewItem,
    trackViewCart,
    getUserProperties,
    trackViewItemList,
    trackSelectItem,
    setCategoryValues,
    trackMarketplaceCTA,
  };
};

export default useGATrackViewActions;
