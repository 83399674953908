import { GA_LOGIN, GA_SIGN_UP } from 'helpers/constants/aafes';
import { useCallback } from 'react';
import { getUserProperties } from './useGATrackViewActions';

const useGATrackUserAction = () => {
  const getCurrentUrl = (): string => {
    return window.location.href;
  };

  const trackLogin = useCallback(() => {
    const gtag = window?.gtag;
    try {
      const login = {
        method: 'AAFES login',
      };
      gtag('event', GA_LOGIN, login);
    } catch (error) {
      console.error('ga4 login event fail', error);
    }
  }, []);

  const trackSignUp = useCallback(() => {
    const gtag = window?.gtag;
    try {
      const signUp = {
        method: 'AAFES Sign_Up',
      };
      gtag('event', GA_SIGN_UP, signUp);
    } catch (error) {
      console.error('ga4 SignUp event fail', error);
    }
  }, []);

  const trackMyAccount = useCallback((eventName, pageId, account: any) => {
    const gtag = window?.gtag;
    try {
      const myAccount = {
        user_properties: getUserProperties(account, calculateAge),
        pageId: pageId,
        page_url: getCurrentUrl(),
      };
      gtag('event', eventName, myAccount);
    } catch (error) {
      console.error('ga4 myAccount event fail', error);
    }
  }, []);

  const trackUserAction = useCallback((eventName, account: any) => {
    const gtag = window?.gtag;
    try {
      const userAccountAction = {
        user_properties: getUserProperties(account, calculateAge),
        page_url: getCurrentUrl(),
      };
      gtag('event', eventName, userAccountAction);
    } catch (error) {
      console.error('ga4 userAccountAction event fail', error);
    }
  }, []);

  const trackSearchResult = useCallback((eventName, searchQuery, account: any) => {
    const gtag = window?.gtag;
    try {
      const searchResult = {
        user_properties: getUserProperties(account, calculateAge),
        page_url: getCurrentUrl(),
        search_term: searchQuery,
      };
      gtag('event', eventName, searchResult);
    } catch (error) {
      console.error('ga4 SearchResult event fail', error);
    }
  }, []);

  const trackErrorPage = useCallback((eventName, errorCode, account: any) => {
    const gtag = window?.gtag;
    try {
      const errorpage = {
        user_properties: getUserProperties(account, calculateAge),
        error_code: errorCode,
        page_id: 'Error Page',
      };
      gtag('event', eventName, errorpage);
    } catch (error) {
      console.error('ga4 errorpage event fail', error);
    }
  }, []);

  const trackCMSPage = useCallback((eventName, pageTitle, pageLocation, siteSections, account: any) => {
    const gtag = window?.gtag;
    try {
      const pageView = {
        user_properties: getUserProperties(account, calculateAge),
        page_title: pageTitle,
        page_location: pageLocation,
        siteSection: siteSections.length > 0 ? siteSections[0] : '',
        siteSection2: siteSections.length > 1 ? siteSections[1] : '',
        siteSection3: siteSections.length > 2 ? siteSections[2] : '',
        siteSection4: siteSections.length > 3 ? siteSections[3] : '',
      };
      gtag('event', eventName, pageView);
    } catch (error) {
      console.error('ga4 pageview event fail', error);
    }
  }, []);

  function calculateAge(birthdayString) {
    if (!birthdayString) {
      return null;
    }
    const dateString = birthdayString.split('T')[0];
    const [year, month, day] = dateString.split('-');
    const birthDate = new Date(year, month - 1, day);
    const currentDate = new Date();
    if (isNaN(birthDate.getTime())) {
      return null;
    }
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const m = currentDate.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && currentDate.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  return {
    trackLogin,
    trackSignUp,
    trackMyAccount,
    trackUserAction,
    calculateAge,
    trackSearchResult,
    trackErrorPage,
    trackCMSPage,
  };
};

export default useGATrackUserAction;
